export default {
  de: {
    ownName: 'Deutsch',
  },
  en: {
    ownName: 'English',
  },
  es: {
    ownName: 'Español',
  },
  et: {
    ownName: 'Eesti',
  },
  fr: {
    ownName: 'Français',
  },
};
