import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ObservationsPicto = props => (
  <SvgIcon {...props} viewBox="-15 -15 122 125">
    <path d="M38.723 53.5c4.25 6.193 9.803 9.807 16.482-4.438C61.42 35.808 64.877 21.5 77.47 15.5c4.359-2 12.593-4.84 12.593-3.5 0-.16 0-1.5-8.36 0-14.65 1.886-24.313 20.54-31.11 34.062-6.797 13.523-6.68-5.573-15.18 2.478-8.502 6.606-17.408 10.472-26.718 11.598 4.23 4.658 14.24 2.445 30.028-6.638zM8.173 80.014c27.317.993 53.434.993 78.353 0 1.216 0-.609-3.115.608-3.115 1.216 0 4.866 4.115 4.866 4.739C92 82.26 88.35 87 87.134 87c-1.217 0 .608-2.493-.608-2.493-.811 0-28.087.416-81.826 1.247-1.503 0-2.722-1.244-2.722-2.775.485-44.327.89-69.576 1.216-75.747.084-1.584-1.825.623-3.041 0C-1.064 6.609 5.379 0 5.379 0s5.835 5.986 5.227 7.232c-.608 1.246-3.046-1.72-3.041 0 .017 6.838.22 31.099.608 72.782z" />
  </SvgIcon>
);

export default ObservationsPicto;
