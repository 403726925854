import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HomepagePicto = props => (
  <SvgIcon {...props} viewBox="-8 -3 110 110">
    <path d="M46 0l46 49.99c-3.18.048-6.18-.083-9-.392v40.355C70.833 93.318 58.667 95 46.5 95c-12.167 0-24.333-1.682-36.5-5.047l.001-42.453c-2.877.35-5.948.516-9.213.499L0 47.99 45.976.025 46 0zM23.144 44.057c-3.019 1.343-6.4 2.343-10.143 3L13 83.456C23.333 86.487 33.667 88 44 88c9.977 0 19.954-1.411 29.931-4.234L75 83.458V48.113c-2.538-.693-4.883-1.576-7.035-2.65C64.668 49.821 57.345 52 46 52c-12.507 0-20.125-2.648-22.856-7.943zm-2.682-10.022L13 42.958v.041h.362c6.284-.045 11.572-1.048 15.862-3.01C30.394 45.329 36.153 48 46.5 48c10.285 0 16.036-2.638 17.253-7.914 4.206 1.966 9.4 2.937 15.581 2.914l.666-.007L48.636 9.79A202.999 202.999 0 0046.5 7.263c-.03-.269-.135-.331-.314-.189l-.063.055L46 7l.017.112.017.11-.034.04-.017.023.008-.142L46 7 20.462 34.035z" />
  </SvgIcon>
);

export default HomepagePicto;
